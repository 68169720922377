import React from "react";
import serviceS1 from "@/images/cyber.jpg";
import serviceS2 from "@/images/planingg.jpg";
const ServiceDetailsContent = () => {
  return (
    <div className="serviceArea">
      <img src={serviceS1} alt="" />
      <h2>Internet-Sicherheit</h2>
      <p>
        Bei Solxk sind wir bestrebt, erstklassige Cyber-Sicherheit zu liefern
        Lösungen zum Schutz Ihrer digitalen Vermögenswerte.Unser Expertenteam
        nutzt branchenführende Tools und Technologien zur Identifizierung von
        Schwachstellen, Risiken mildern und Ihre Systeme sichern.Wir erkennen
        das Kritische an Wichtigkeit, Ihre digitalen Vermögenswerte vor dem sich
        ständig weiterentwickelnden Vermögen zu schützen Landschaft der Cyber
        ​​-Bedrohungen.Unsere umfassenden Cybersecurity -Lösungen sind akribisch
        entwickelt, um Unternehmen aller Größen in zu unterstützen mildernde
        Risiken, Schutz sensibler Daten und Aufrechterhaltung der Regulierung
        Einhaltung eines immer komplexeren Bedrohungsumfelds.
      </p>
      <div className="row gaping">
        <div className="col-lg-6 col-sm-12 col-md-6">
          <img src={serviceS2} alt="" />
        </div>
        <div className="col-lg-6 col-sm-12 col-md-6">
          <h3>Planung und Strategie</h3>
          <p>Dienstleistungen, die wir anbieten:</p>
          <ul>
            <li>
              {/* <i className="fa fa-check-square"></i>Maltego: A powerful tool for
              link analysis,
            </li> */}
              <i className="fa fa-check-square"></i>Penetrationstests
            </li>
            {/* <li>
            <i className="fa fa-check-square"></i>Vulnerability Assessment
            </li> */}
            {/* <i className="fa fa-check-square"></i>Burp Suite:Allows us to
              identify weaknesses
            </li> */}
            {/* <li>
              <i className="fa fa-check-square"></i>Nmap:discover hosts and
              services
            </li> */}
            <li>
              <i className="fa fa-check-square"></i>Anwendungsquellcode
              Bewertungen
            </li>
            {/* <li>
              <i className="fa fa-check-square"></i>Metasploit: find and fix
              security gaps proactively.
            </li> */}
            <li>
              <i className="fa fa-check-square"></i>Compliance-basierte
              Offensive Anforderungen
            </li>
            {/* <li>
              <i className="fa fa-check-square"></i>GVM (OpenVAS): perform
              comprehensive scans...
            </li> */}
            <li>
              <i className="fa fa-check-square"></i> OSINT MALTEGO -Integration
              mit Training
            </li>
            <li>
              <i className="fa fa-check-square"></i>Siem Solutions
              -Impletationen
            </li>
            <li>
              <i className="fa fa-check-square"></i>WAF Solutions Integrations
            </li>
            {/* <li>
              <i className="fa fa-check-square"></i>Threat Hunting
            </li> */}
            {/* <li>
              <i className="fa fa-check-square"></i>CyberSecurity Training
            </li> */}
          </ul>
        </div>
      </div>
      <p>
        Egal, ob Sie ein Startup sind, um eine starke Markenidentität
        festzulegen oder ein etabliertes Unternehmen, das darauf abzielt, Ihre
        visuelle Präsenz zu aktualisieren, Solxk ist hier, um Ihnen zu
        helfen.Kontaktieren Sie uns noch heute, um Ihr Design zu besprechen
        Bedürfnisse und tun Sie den ersten Schritt, um Ihre Marke in a zu
        verwandeln visuelles Meisterwerk.
      </p>
      <p>
        Bei Solxk erkennen wir an, dass die Grundlage eines erfolgreichen Webs
        Entwicklungsprojekt liegt in der akribischen Planung.Vor dem Schreiben
        einer Single Codelinie oder Entwerfen eines einzelnen Pixels investieren
        wir Zeit in die Zeit Verstehen Sie Ihre Geschäftsziele, Zielgruppen und
        einzigartig Anforderungen.Unser umfassender Planungsprozess sorgt dafür
        Aspekt Ihres Projekts wird sorgfältig berücksichtigt und mit ausgeführt
        Präzision.
      </p>
    </div>
  );
};

export default ServiceDetailsContent;
