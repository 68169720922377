import React, { Fragment } from "react";

const ServiceSidebar = () => {
  return (
    <Fragment>
      <aside className="widget categories">
        <h3 className="widget_title">Dienstleistungen</h3>
        <div className="meipaly_categorie_widget">
          <ul>
            <li>
              <a href="/website-development">Webseitenentwicklung</a>
            </li>
            <li>
              <a href="/website-graphics">Grafikdesign</a>
            </li>
            <li>
              <a href="/digital-marketing">Digitales Marketing</a>
            </li>
            <li>
              <a href="/cyber-security">Internet-Sicherheit</a>
            </li>
            <li>
              <a href="/app-development">App -Entwicklung</a>
            </li>
          </ul>
        </div>
      </aside>
      <aside className="widget categories">
        <div className="meipaly_services_help">
          <h4>Benötigen Sie Solxk helfen?</h4>
          <p>
            Sprechen Sie lieber mit einem Menschen, um ein Formular
            auszufüllen?Corporate anrufen Büro und wir werden Sie mit einem
            Teammitglied verbinden, das helfen kann.
          </p>
          <h2>049 000 000</h2>
        </div>
      </aside>
    </Fragment>
  );
};

export default ServiceSidebar;
